<template>
  <v-card>
    <v-card-title>
    Overview of mailing lists and recipients
    </v-card-title>
    <v-card-text>
      <v-select
        v-model="mailingList"
        :items="mailingLists"
        :item-text="getMailingListText"
        return-object
        outlined
        dense
        block
        label="Mailing List"
      >
      </v-select>
    <template v-if="mailingList">
      <h3>{{mailingList.description}}</h3>
      <hr class="my-2">
      <v-data-table
        :items="mailingList.contacts"
        :headers="headers"
        hide-default-footer
        :disable-pagination=true
        dense
      >
    </v-data-table>
    </template>
    <template v-else>
      <h3>Select a mailing list to view recipients</h3>
    </template>
  </v-card-text>
  </v-card>
</template>

<script>
import MailingListApi from '@/api/mailingList'
import MailingList from '@/constants/mailingLists'

export default {
  data () {
    return {
      selectedContact: "",
      mailingList: null,
      mailingLists: [],
      searchText: null,
      headers: [
        { text: "Name", value: "name" },
        { text: "First Name", value: "firstName" },
        { text: "Email", value: "emailAddress" },
      ],
    }
  },
  async created () {
    MailingListApi.loadMailingLists().then( resp =>{
      const _mailingLists = Object.keys(resp.data)
      this.mailingLists = _mailingLists.map( key => ( {
        key: key,
        name: MailingList[key].name,
        description: MailingList[key].description,
        contacts: resp.data[key]}
      ) )
    })
  },
  methods: {
    getMailingListText: (item) => `${item.name} (${item.contacts?.length})`,
  }}
</script>

</script>
<style lang="scss" scoped>
::v-deep .clickable {
  cursor: pointer
}
::v-deep td, ::v-deep th {
  padding: 0 !important}
  </style>
